import React, { useState } from "react";
import { motion } from "framer-motion";
import emailjs from "emailjs-com";
import home from "../pictures/home.jpg";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    date: "",
    eventType: "",
    guests: "",
    questions: "",
  });

  const [status, setStatus] = useState("");  // For displaying error or success message
  const [isSubmitting, setIsSubmitting] = useState(false);  // For loader state
  const [applicationSubmitted, setApplicationSubmitted] = useState(false);  // New state for success confirmation

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Show loader

    // Simple validation
    if (!formData.name || !formData.email || !formData.phone) {
      setStatus("Please fill in all required fields.");
      setIsSubmitting(false);
      return;
    }

    // Send the form data to your email using EmailJS
    emailjs
    .send("service_591jogh", "nimantran_x31u5lr", formData, "rIC5PpQPvMA_OMC-J")
    .then(
        (response) => {
          console.log("Email sent successfully:", response);
          setStatus("Meddelande skickat!");
          setApplicationSubmitted(true);  // Show success message
          setIsSubmitting(false); // Disable loader
          setFormData({
            name: "",
            email: "",
            phone: "",
            date: "",
            eventType: "",
            guests: "",
            questions: "",
          });
        },
        (error) => {
          console.error("Email sending failed:", error);
          setStatus("Misslyckades att skicka meddelandet. Försök igen.");
          setIsSubmitting(false); // Disable loader
        }
      );
  };

  const handleEmailClick = () => {
    const email = "info@nimantran.se";
    window.location.href = `mailto:${email}`;
  };

  return (
    <div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div>
          <img
            alt="home"
            src={home}
            className="md:h-60 h-36 w-auto mt-4 mx-auto rounded-lg shadow-xl shadow-amber-900/40"
          />
        </div>

        <div className="mx-12 my-16">
          <h1 className="text-4xl font-bold text-red-900 drop-shadow-xl">
            Kontakta oss
          </h1>
          <p className="mt-4 text-font text-lg drop-shadow-lg">
            Vi är ett lokalt uthyrningsföretag som är dedikerade till att
            tillhandahålla prisvärd lokalhyra till vårt samhälle.
          </p>
        </div>

        <div className="flex flex-col md:flex-row gap-12 mx-12">
          <div className="flex-1">
            <h2 className="text-font text-2xl font-semibold drop-shadow-lg">
              Skicka ett meddelande
            </h2>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-2 mt-6 bg-gray-50 p-4 rounded-lg shadow-md"
            >
              {/* Form fields... */}

              {/* Submit Button */}
              <div className="flex justify-end mt-2">
                <button
                  type="submit"
                  className="bg-red-900 text-white py-1 px-3 rounded hover:bg-red-700 text-sm"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <span className="loader"></span> // You can use a loader component here
                  ) : (
                    "Skicka"
                  )}
                </button>
              </div>
            </form>
            {status && (
              <p className="mt-4 text-font text-lg font-semibold">{status}</p>
            )}
          </div>

          {/* Success message */}
          {applicationSubmitted && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                <h2 className="text-2xl font-bold text-green-600">Application Submitted!</h2>
                <p className="mt-4">Thank you for contacting us. We will get back to you shortly.</p>
                <button
                  onClick={() => setApplicationSubmitted(false)}  // Close the success modal
                  className="mt-4 bg-red-900 text-white py-1 px-4 rounded hover:bg-red-700"
                >
                  Close
                </button>
              </div>
            </div>
          )}

          {/* Contact Info */}
          <div className="flex-1">
            <div className="my-8">
              <h2 className="text-font text-2xl font-semibold drop-shadow-lg">
                Vår adress
              </h2>
              <p className="text-font text-lg font-normal drop-shadow-lg">
                Svaneholm 295, 274 91 Skurup, Skåne Län, Sweden
              </p>
            </div>

            <div className="my-8">
              <h2 className="text-font text-2xl font-semibold drop-shadow-lg">
                Email
              </h2>
              <p onClick={handleEmailClick}>
                <span className="text-font text-lg font-medium underline cursor-pointer drop-shadow-lg hover:text-xl transition-all">
                  info@nimantran.se
                </span>
              </p>
            </div>

            <div className="my-8">
              <h2 className="text-font text-2xl font-semibold drop-shadow-lg">
                Telefon
              </h2>
              <p className="text-font text-lg font-normal underline drop-shadow-lg">
                +46 (0) 73-510 85 04
              </p>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Contact;
