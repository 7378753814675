import React, { useState } from "react";
import { motion } from "framer-motion";
import pic4 from "../pictures/pic4.webp";
import pic5 from "../pictures/pic5.webp";
import pic6 from "../pictures/pic6.webp";
import pic7 from "../pictures/pic7.webp";
import pic8 from "../pictures/pic8.webp";
import pic13 from "../pictures/pic13.webp";
import pic15 from "../pictures/pic15.webp";
import pic16 from "../pictures/pic16.webp";
import pic19 from "../pictures/pic19.webp";
import pic20 from "../pictures/pic20.webp";
import pic21 from "../pictures/pic21.webp";
import pic22 from "../pictures/pic22.webp";
import pic23 from "../pictures/pic23.webp";
import pic03 from "../pictures/pic-03.webp";
import pic05 from "../pictures/pic-05.webp";
import pic06 from "../pictures/pic-06.webp";
import pic1 from "../pictures/pic-1.webp";
import pic3 from "../pictures/pic-3.webp";
import pic9 from "../pictures/pic-9.webp";
import pic10 from "../pictures/pic-10.webp";
import pic11 from "../pictures/pic-11.webp";
import pic12 from "../pictures/pic-12.webp";
import pic14 from "../pictures/pic-14.webp";
import pic24 from "../pictures/pic-24.webp";
import pic25 from "../pictures/pic-25.webp";
import pic26 from "../pictures/pic-26.webp";
import pic27 from "../pictures/pic-27.webp";
import pic28 from "../pictures/pic-28.webp";
import pic29 from "../pictures/pic-29.webp";
import pic30 from "../pictures/pic-30.webp";
import pic31 from "../pictures/pic-31.webp";
import pic32 from "../pictures/pic-32.webp";
import pic33 from "../pictures/pic-33.webp";
import pic34 from "../pictures/pic-34.webp";
import pic35 from "../pictures/pic-35.webp";

const pictures = [
  pic26,
  pic27,
  pic28,
  pic29,
  pic30,
  pic31,
  pic32,
  pic33,
  pic34,
  pic35, 
  pic1,
  pic3,
  pic9,
  pic10,
  pic11,
  pic12,
  pic14,
  pic24,
  pic25,
  pic21,
  pic20,
  pic22,
  pic23,
  pic19,
  pic16,
  pic4,
  pic5,
  pic6,
  pic7,
  pic8,
  pic13,
  pic15,
  pic03,
  pic05,
  pic06
];

const PicturePage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPicture, setSelectedPicture] = useState("");

  const openPicture = (picture) => {
    setIsOpen(true);
    setSelectedPicture(picture);
  };

  const closePicture = () => {
    setIsOpen(false);
    setSelectedPicture("");
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 m-4 mx-14 mb-16">
        {pictures.map((picture, index) => (
          <motion.img
            key={index}
            src={picture}
            alt={`Picture ${index + 1}`}
            className="w-full h-full object-cover cursor-pointer rounded-md shadow-md shadow-amber-900/40"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.2 }}
            onClick={() => openPicture(picture)}
          />
        ))}

        {isOpen && (
          <div
            className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-75 "
            onClick={closePicture}
          >
            <img
              src={selectedPicture}
              alt="Local pictures"
              className="max-w-full max-h-full"
            />
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default PicturePage;